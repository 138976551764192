import {combineReducers} from 'redux'
import authentication from './authentication'
import profile from './profile'
import accountUserActivityFeedItems from 'v2/spas/account-user/activity-feed-items/redux/reducers/index.tsx'
import workflow from 'v2/spas/workflow/redux/reducers/view.tsx'
import accountUserInvestmentOpportunityInvitations from 'v2/spas/account-user/investment-opportunity-invitations/redux/reducers/index.tsx'
import accountUserInvestmentOpportunityInvitation from 'v2/spas/account-user/investment-opportunity-invitations/redux/reducers/view.tsx'
import investmentOpportunityPresentation from 'v2/spas/investment-opportunity/presentations/redux/reducers/view.tsx'
import investmentOpportunityPledges from 'v2/spas/investment-opportunity/pledges/redux/reducers/index.tsx'

const rootReducer = combineReducers({
  authentication,
  profile,
  accountUserActivityFeedItems,
  workflow,
  accountUserInvestmentOpportunityInvitations,
  accountUserInvestmentOpportunityInvitation,
  investmentOpportunityPresentation,
  investmentOpportunityPledges,
})

export default rootReducer
