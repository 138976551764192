export const LOAD_STACKED_REQUEST = 'LOAD_STACKED_REQUEST'
export const LOAD_STACKED_SUCCESS = 'LOAD_STACKED_SUCCESS'
export const LOAD_STACKED_FAILURE = 'LOAD_STACKED_FAILURE'

export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'

export const SIGN_IN_WITH_MAGIC_LINK_REQUEST = 'SIGN_IN_WITH_MAGIC_LINK_REQUEST'
export const SIGN_IN_WITH_MAGIC_LINK_SUCCESS = 'SIGN_IN_WITH_MAGIC_LINK_SUCCESS'
export const SIGN_IN_WITH_MAGIC_LINK_FAILURE = 'SIGN_IN_WITH_MAGIC_LINK_FAILURE'

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE'

export const SESSION_REQUEST = 'SESSION_REQUEST'
export const SESSION_SUCCESS = 'SESSION_SUCCESS'
export const SESSION_FAILURE = 'SESSION_FAILURE'

export const FETCH_CURRENT_ACCOUNT_REQUEST = 'FETCH_CURRENT_ACCOUNT_REQUEST'
export const FETCH_CURRENT_ACCOUNT_SUCCESS = 'FETCH_CURRENT_ACCOUNT_SUCCESS'
export const FETCH_CURRENT_ACCOUNT_FAILURE = 'FETCH_CURRENT_ACCOUNT_FAILURE'

export const FETCH_CURRENT_EMAIL_IDENTITY_REQUEST = 'FETCH_CURRENT_EMAIL_IDENTITY_REQUEST'
export const FETCH_CURRENT_EMAIL_IDENTITY_SUCCESS = 'FETCH_CURRENT_EMAIL_IDENTITY_SUCCESS'
export const FETCH_CURRENT_EMAIL_IDENTITY_FAILURE = 'FETCH_CURRENT_EMAIL_IDENTITY_FAILURE'

export const FETCH_CURRENT_USER_REQUEST = 'FETCH_CURRENT_USER_REQUEST'
export const FETCH_CURRENT_USER_SUCCESS = 'FETCH_CURRENT_USER_SUCCESS'
export const FETCH_CURRENT_USER_FAILURE = 'FETCH_CURRENT_USER_FAILURE'

export const FETCH_CURRENT_USER_POLICY_REQUEST = 'FETCH_CURRENT_USER_POLICY_REQUEST'
export const FETCH_CURRENT_USER_POLICY_SUCCESS = 'FETCH_CURRENT_USER_POLICY_SUCCESS'
export const FETCH_CURRENT_USER_POLICY_FAILURE = 'FETCH_CURRENT_USER_POLICY_FAILURE'

export const UPDATE_AVATAR_REQUEST = 'UPDATE_AVATAR_REQUEST'
export const UPDATE_AVATAR_SUCCESS = 'UPDATE_AVATAR_SUCCESS'
export const UPDATE_AVATAR_FAILURE = 'UPDATE_AVATAR_FAILURE'

export const UPDATE_LOGO_REQUEST = 'UPDATE_LOGO_REQUEST'
export const UPDATE_LOGO_SUCCESS = 'UPDATE_LOGO_SUCCESS'
export const UPDATE_LOGO_FAILURE = 'UPDATE_LOGO_FAILURE'

export const ADD_REMINDER_TO_CALENDAR_EVENT_REQUEST = 'ADD_REMINDER_TO_CALENDAR_EVENT_REQUEST'
export const ADD_REMINDER_TO_CALENDAR_EVENT_SUCCESS = 'ADD_REMINDER_TO_CALENDAR_EVENT_SUCCESS'
export const ADD_REMINDER_TO_CALENDAR_EVENT_FAILURE = 'ADD_REMINDER_TO_CALENDAR_EVENT_FAILURE'