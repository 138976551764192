import React, { useState } from 'react'
import { Breadcrumbs } from 'pages'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Link } from 'react-router-dom'

function Navigation() {

  const [showSupportVideo, setShowSupportVideo] = useState(false)

  return (
    <Breadcrumbs
      supportVideoLink='https://www.loom.com/embed/9380f0f63fd24b20866e4acd346d2a45?sid=7bbbef2e-ff90-452f-8673-5051d537a979'
      supportVideoHeading='Welcome to your Stacked account'
      showSupportVideo={showSupportVideo}
      setShowSupportVideo={setShowSupportVideo}
    >
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>Dashboard</Breadcrumb.Item>
      </Breadcrumb>
    </Breadcrumbs>
  )
}

export default Navigation