import update from 'immutability-helper'
import { FETCH_INVESTMENT_OPPORTUNITY_PLEDGES_REQUEST } from 'v2/spas/investment-opportunity/pledges/redux/action-types.tsx'
import { FETCH_INVESTMENT_OPPORTUNITY_PLEDGES_SUCCESS } from 'v2/spas/investment-opportunity/pledges/redux/action-types.tsx'
import { FETCH_INVESTMENT_OPPORTUNITY_PLEDGES_FAILURE } from 'v2/spas/investment-opportunity/pledges/redux/action-types.tsx'
import { DELETE_PLEDGE_REQUEST } from 'v2/spas/investment-opportunity/pledges/redux/action-types.tsx'
import { DELETE_PLEDGE_SUCCESS } from 'v2/spas/investment-opportunity/pledges/redux/action-types.tsx'
import { DELETE_PLEDGE_FAILURE } from 'v2/spas/investment-opportunity/pledges/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  investmentOpportunityInvitation: {},
  pledges: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_INVESTMENT_OPPORTUNITY_PLEDGES_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_INVESTMENT_OPPORTUNITY_PLEDGES_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_INVESTMENT_OPPORTUNITY_PLEDGES_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        investmentOpportunityInvitation: {
          $set: action.payload.investmentOpportunityInvitation,
        },
        pledges: {
          $set: action.payload.investmentOpportunityInvitation.investmentOpportunity.currentAccountUserPledges
        },
      })
    }

    case DELETE_PLEDGE_REQUEST: {
      return state
    }
    
    case DELETE_PLEDGE_FAILURE: {
      return state
    }
    
    case DELETE_PLEDGE_SUCCESS: {
      let index = state.pledges.findIndex(x => x.id === action.payload.id)
      
      return update(state, {
        pledges: {
          $splice: [[index, 1]],
        },
      })
    }

    default:
      return state
  }
}
