import update from 'immutability-helper'
import { FETCH_INVESTMENT_OPPORTUNITY_INVITATION_REQUEST } from 'v2/spas/account-user/investment-opportunity-invitations/redux/action-types.tsx'
import { FETCH_INVESTMENT_OPPORTUNITY_INVITATION_SUCCESS } from 'v2/spas/account-user/investment-opportunity-invitations/redux/action-types.tsx'
import { FETCH_INVESTMENT_OPPORTUNITY_INVITATION_FAILURE } from 'v2/spas/account-user/investment-opportunity-invitations/redux/action-types.tsx'
import { UPDATE_INVESTMENT_OPPORTUNITY_INVITATION_REQUEST } from 'v2/spas/account-user/investment-opportunity-invitations/redux/action-types.tsx'
import { UPDATE_INVESTMENT_OPPORTUNITY_INVITATION_SUCCESS } from 'v2/spas/account-user/investment-opportunity-invitations/redux/action-types.tsx'
import { UPDATE_INVESTMENT_OPPORTUNITY_INVITATION_FAILURE } from 'v2/spas/account-user/investment-opportunity-invitations/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  investmentOpportunityInvitation: {}
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_INVESTMENT_OPPORTUNITY_INVITATION_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_INVESTMENT_OPPORTUNITY_INVITATION_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_INVESTMENT_OPPORTUNITY_INVITATION_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        investmentOpportunityInvitation: {
          $set: action.payload.investmentOpportunityInvitation,
        }
      })
    }

    case UPDATE_INVESTMENT_OPPORTUNITY_INVITATION_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case UPDATE_INVESTMENT_OPPORTUNITY_INVITATION_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case UPDATE_INVESTMENT_OPPORTUNITY_INVITATION_SUCCESS: {
      return state
    }

    default:
      return state
  }
}