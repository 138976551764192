export const FETCH_INVESTMENT_OPPORTUNITY_PLEDGES_REQUEST = 'FETCH_INVESTMENT_OPPORTUNITY_PLEDGES_REQUEST'
export const FETCH_INVESTMENT_OPPORTUNITY_PLEDGES_SUCCESS = 'FETCH_INVESTMENT_OPPORTUNITY_PLEDGES_SUCCESS'
export const FETCH_INVESTMENT_OPPORTUNITY_PLEDGES_FAILURE = 'FETCH_INVESTMENT_OPPORTUNITY_PLEDGES_FAILURE'

export const CREATE_PLEDGE_REQUEST = 'CREATE_PLEDGE_REQUEST'
export const CREATE_PLEDGE_SUCCESS = 'CREATE_PLEDGE_SUCCESS'
export const CREATE_PLEDGE_FAILURE = 'CREATE_PLEDGE_FAILURE'

export const DELETE_PLEDGE_REQUEST = 'DELETE_PLEDGE_REQUEST'
export const DELETE_PLEDGE_SUCCESS = 'DELETE_PLEDGE_SUCCESS'
export const DELETE_PLEDGE_FAILURE = 'DELETE_PLEDGE_FAILURE'