export const FETCH_ACCOUNT_USER_INVESTMENT_OPPORTUNITY_INVITATIONS_REQUEST = 'FETCH_ACCOUNT_USER_INVESTMENT_OPPORTUNITY_INVITATIONS_REQUEST'
export const FETCH_ACCOUNT_USER_INVESTMENT_OPPORTUNITY_INVITATIONS_SUCCESS = 'FETCH_ACCOUNT_USER_INVESTMENT_OPPORTUNITY_INVITATIONS_SUCCESS'
export const FETCH_ACCOUNT_USER_INVESTMENT_OPPORTUNITY_INVITATIONS_FAILURE = 'FETCH_ACCOUNT_USER_INVESTMENT_OPPORTUNITY_INVITATIONS_FAILURE'

export const FETCH_INVESTMENT_OPPORTUNITY_INVITATION_REQUEST = 'FETCH_INVESTMENT_OPPORTUNITY_INVITATION_REQUEST'
export const FETCH_INVESTMENT_OPPORTUNITY_INVITATION_SUCCESS = 'FETCH_INVESTMENT_OPPORTUNITY_INVITATION_SUCCESS'
export const FETCH_INVESTMENT_OPPORTUNITY_INVITATION_FAILURE = 'FETCH_INVESTMENT_OPPORTUNITY_INVITATION_FAILURE'

export const UPDATE_INVESTMENT_OPPORTUNITY_INVITATION_REQUEST = 'UPDATE_INVESTMENT_OPPORTUNITY_INVITATION_REQUEST'
export const UPDATE_INVESTMENT_OPPORTUNITY_INVITATION_SUCCESS = 'UPDATE_INVESTMENT_OPPORTUNITY_INVITATION_SUCCESS'
export const UPDATE_INVESTMENT_OPPORTUNITY_INVITATION_FAILURE = 'UPDATE_INVESTMENT_OPPORTUNITY_INVITATION_FAILURE'