import React from 'react'
import { CardGrid } from 'grids'
import Col from 'react-bootstrap/Col'
import { GridSectionHeading } from 'headings'
import { AssociationCard } from 'cards'
import { CashStack } from 'react-bootstrap-icons';
import { useCurrentAccountUser } from 'functions'

function Grid() {

  let accountUser = useCurrentAccountUser()

  let counters = accountUser.counters || {}
  let investmentOpportunityInvitationsCounter = counters.investmentOpportunityInvitations || {}
  
  return (
    <CardGrid>
      <Col lg='12'>
        <GridSectionHeading
          heading='What do you want to do?'
          description='Here are some helpful shortcuts, to point you in the right direction'
        />
      </Col>

      <Col xs='6' lg='3' id='association-grid-card'>
        <AssociationCard
          link={`/account-users/${accountUser.id}/investment-opportunity-invitations`}
          heading='Investment opportunities'
          icon={<CashStack size={50} />}
          description='Review the investment opportunities, which have been shared with you'
          count={investmentOpportunityInvitationsCounter.active}
        />
      </Col>
    </CardGrid>
  )
}

export default Grid